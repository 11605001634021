import * as R from 'ramda'
import {navigate} from 'gatsby'
import React, {useEffect} from 'react'

import {locationPropTypes} from 'helpers/propTypes'
import useIsBrowser from 'hooks/useIsBrowser'

import {countriesByLocales} from '../../keyrus/static/countries/countriesByLocales'
import {countriesByLocalesKf} from '../../keyrus-fondation/static/countriesByLocalesKf'
import {countriesByLocalesKls} from '../../keyrus-life-science/static/countriesByLocalesKls'
import {countriesByLocalesKm} from '../../keyrus-management/static/countriesByLocalesKm'
import {countriesByLocalesNs} from '../../keyrus-nearshoring/static/countriesByLocalesNs'

const countriesByLocalesMap = {
  keyrus: countriesByLocales,
  kls: countriesByLocalesKls,
  'keyrus-management': countriesByLocalesKm,
  'keyrus-nearshoring': countriesByLocalesNs,
  'keyrus-china': countriesByLocales,
}

const getDefaultRoute = website => {
  switch (website) {
    case 'keyrus':
      return '/worldwide/home'
    case 'kls':
      return '/en/en/home'
    case 'keyrus-management':
      return '/en/en/home'
    case 'keyrus-nearshoring':
      return '/en/en/home'
    case 'keyrus-fondation':
      return '/en/en/home'
    default:
      return '/'
  }
}

const NotFoundPage = ({location}) => {
  const {pathname} = location
  const country = useIsBrowser && location && location.pathname.split('/')[1]
  const isRoot = pathname === '/' || pathname === ' '

  useEffect(() => {
    const website = process.env.GATSBY_WEBSITE
    const countriesByLocales = countriesByLocalesMap[website]

    if (!countriesByLocales) {
      navigate('/worldwide/page-not-found')

      return
    }

    const [currentCountry] = R.filter(
      e => e.countryCode === country,
      countriesByLocales,
    )

    if (isRoot) {
      navigate(getDefaultRoute(website))
    } else if (!currentCountry) {
      navigate('/worldwide/page-not-found')
    } else if (currentCountry.countryCode === 'worldwide') {
      navigate(`/${currentCountry.countryCode}/page-not-found`)
    } else {
      const locale =
        website === 'keyrus-china'
          ? 'zh'
          : currentCountry.localeCode.slice(0, 2)

      navigate(`/${currentCountry.countryCode}/${locale}/page-not-found`)
    }
  }, [country, isRoot, pathname])

  return <div />
}

NotFoundPage.propTypes = {
  location: locationPropTypes.isRequired,
}

export default NotFoundPage
