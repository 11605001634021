const countriesByLocalesNs = [
  {
    name: 'Keyrus Nearshoring - EN',
    technicalName: 'Keyrus Nearshoring - EN',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'en',
    countryCode: 'en',
    continent: 'Global',
    salesforceCountry: 'r',
    campaignToken: 'r',
  },
]

module.exports = {
  countriesByLocalesNs,
}
