const countriesByLocalesKf = [
  {
    name: 'Keyrus Fondation - EN',
    technicalName: 'Keyrus Fondation - EN',
    locale: 'U.S. English (en-US)',
    localeCode: 'en-US',
    localeName: 'English',
    urlPrefix: 'en',
    countryCode: 'en',
    continent: 'Global',
    salesforceCountry: 'r',
    campaignToken: 'r',
  },
  {
    name: 'Keyrus Fondation - FR',
    technicalName: 'Keyrus Fondation - FR',
    locale: 'French (France) (fr-FR)',
    localeCode: 'fr-FR',
    localeName: 'French',
    urlPrefix: 'fr',
    countryCode: 'fr',
    continent: 'Global',
    salesforceCountry: 'r',
    campaignToken: 'r',
  },
]

module.exports = {
  countriesByLocalesKf,
}
